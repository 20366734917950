import {Bus as Vuedals} from '../external/vudals/main';

// Add to doom
export default function showModal(component, props, data, callback = null, dismissable = true, name = 'modal') {
  // Show the save quote modal
  Vuedals.$emit('new', {
      name: name,
      dismissable: dismissable,
      component: component,
      props: props,
      onClose() {
        if (callback) {
          callback(data);
        }
      },
      onDismiss() {
        if (callback) {
          callback(data);
        }
      }
    },
  );
}
