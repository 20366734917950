import {Bus as Vuedals} from '../external/vudals/main';
import uiMessage from './components/cmp-ui-message.vue';


// Add to doom
export default function showMessage(message, data, callback = null) {

  // Show the save quote modal
  Vuedals.$emit('new', {
      name: 'logMessage',
      component: uiMessage,
      props: {
        message: message,
      },
      onClose() {
        if (callback) {
          callback(data);
        }
      },
      onDismiss() {
        if (callback) {
          callback(data);
        }
      }
    },
  );
}
