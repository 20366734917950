import axios from 'axios'

// Add to doom
export default function postData(url, data, onSuccess = null, onFailure = null) {

  /**
   * Call the success function
   *
   * @param data
   */
  function processSuccess(data = null) {
    if (onSuccess) {
      onSuccess(data);
    }
  }


  /**
   * Show an info message
   *
   * @param title
   * @param message
   * @param data
   */
  function processMessage(message, data = null) {
    let message_div = document.getElementById("message-wrapper");
    if (message_div) {
      let msg = '';
      if (message === Object(message)) {
        msg = Object.values(message).join("\n");
      } else {
        msg = message;
      }
      msg = msg.replace(/_/g, ' ');
      message_div.innerHTML = msg;
      if (message_div.innerHTML.trim() != '') {
        message_div.style.display = 'block';
      }else{
        message_div.style.display = 'none';
      }
    } else {
      ui.showMessage(message, data, processSuccess);
    }
  }


  /**
   * Show an error message
   *
   * @param title
   * @param message
   * @param data
   */
  function processFailure(message, data = null) {
    let error_div = document.getElementById("error-wrapper");
    if (error_div) {
      let msg = '';
      if (message === Object(message)) {
        msg = Object.values(message).join("\n");
      } else {
        msg = message;
      }
      msg = msg.replace(/_/g, ' ');
      error_div.innerHTML = msg;
      if (error_div.innerHTML.trim() != '') {
        error_div.style.display = 'block';
      }else{
        error_div.style.display = 'none';
      }
    }

    if (onFailure) {
      onFailure(data);
      // ui.showError(message, data, onFailure);
    } else {
      ui.showError(message);
    }
  }

  let message_div = document.getElementById("message-wrapper");
  if (message_div) {
    message_div.style.display = 'none';
    message_div.innerHTML = '';
  }
  let error_div = document.getElementById("error-wrapper");
  if (error_div) {
    error_div.style.display = 'none';
    error_div.innerHTML = '';
  }

  // console.log({post: data});
  axios.post(url, JSON.stringify(data))
    .then(function (response) {
      // console.log({ajax: response});
      if (!_.has(response, 'status') || response.status != 200) {
        // console.log({error: response});
        processFailure('Please refresh the page and try again in a few minutes, your session might have timed out.');
      } else if (!_.has(response, 'data')) {
        processFailure('Please check your internet connection.');
      } else if (!_.has(response.data, 'errors')) {
        processFailure('Please refresh the page and try again in a few minutes.');
      } else if (response.data.errors && (response.data.errors === Object(response.data.errors) && Object.values(response.data.errors).length)) {
        // Show errors
        processFailure(response.data.errors, response.data);
      } else if (_.has(response.data, 'response')) {
        if (_.has(response.data.response, 'success')) {
          if (response.data.response.success) {


            if (response.data.messages) {
              // Show messages
              processMessage(response.data.messages);
            } else {
              // All good, no message
              processSuccess(response.data.response);
            }

          } else {
            processFailure('Action failed, please try again in one minute.', response.data)
          }
        } else {
          // No success
          processFailure('Action failed, please try again in one minute.', response.data)
        }
      } else {
        // No API response?
        processFailure('Could not read API response, please refresh the page and try again in one minute.');
      }
    })
    .catch(function (error) {
      // console.log({error: error});
      processFailure('Please refresh the page and try again in a few minutes, your session might have timed out.');
    });

}
