import Vue from 'vue'

// Modals
import {default as Vuedals, Component as Vuedal, Bus as VuedalsBus} from '../external/vudals/main';

Vue.use(Vuedals);

const myVuedal = new Vue({
  components: {
    Vuedal
  },
  template: `<span><vuedal></vuedal></span>`
}).$mount();


document.body.appendChild(myVuedal.$el);

import showError from './ui-error';
import showMessage from './ui-message';
import showModal from './ui-modal';
import postData from './ajax';

window.ui = {
  /**
   * Show a modal (component provided)
   */
  showModal: showModal,
  /**
   * Show an error modal
   */
  showError: showError,
  /**
   * Show a message modal
   */
  showMessage: showMessage,
  /**
   * Close last actvated modal
   */
  closeLastModal: function() {
    VuedalsBus.$emit('close');
  },
  /**
   * Make an AJAX JSON post
   */
  post: postData,
}
