import {Bus as Vuedals} from '../external/vudals/main';
import uiError from './components/cmp-ui-error.vue';

// Add to doom
export default function showError(message, data, callback = null) {
  // Show the save quote modal
  Vuedals.$emit('new', {
      name: 'logError',
      component: uiError,
      props: {
        message: message,
      },
      onClose() {
        if (callback) {
          callback(data);
        }
      },
      onDismiss() {
        if (callback) {
          callback(data);
        }
      }
    },
  );
}
