<template>
  <div>
    <h1>{{ title }}</h1>
    <p><span v-html="message"/></p>

    <hr/>
    <div class="right">
      <a class="button button-outline" @click="close()">Ok</a>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      name: 'logError',
      title: {
        required: false,
        default: 'Error'
      },
      message: {
        required: true,
      }
    },
    methods: {
      close: function () {
        window.ui.closeLastModal();
      },
    }
  };
</script>
<style lang="scss" scoped>
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8
  {
    color: red;

    &:after {
      background-color: red;
    }
  }
</style>
